<template>
  <BackofficeBase :loader_prop="loader_prop">
    <div v-if="! show_ty">
      <form @submit="backoffice_user_save">
        <div>
          <div>
            Email:
          </div>
          <div>
            <input v-model="email" type="text" v-bind:class="{input_error : email_input_error}" />
            <div class="input_error_txt" v-if="email_input_error">
              Email error
            </div>
          </div>
        </div>
        <div>
          <div>
            Phone:
          </div>
          <div>
            <input v-model="phone" type="text" v-bind:class="{input_error : phone_input_error}" />
            <div class="input_error_txt" v-if="phone_input_error">
              Phone error
            </div>
          </div>
        </div>
        <div>
          <div>
            Full Name:
          </div>
          <div>
            <input v-model="full_name" type="text" v-bind:class="{input_error : full_name_input_error}" />
            <div class="input_error_txt" v-if="full_name_input_error">
              Full name error
            </div>
          </div>
        </div>
        <div v-if="backoffice_user_id == '0'">
          <div>
            Password:
          </div>
          <div>
            <input v-model="password" type="password" v-bind:class="{input_error : password_input_error}" />
            <div class="input_error_txt" v-if="password_input_error">
              Password error
            </div>
          </div>
        </div>
        <div class="submit_cont">
          <input type="submit" value="Save" />
        </div>
      </form>
    </div>
    <div v-if="show_ty">
      Done!<br />
      <router-link to="/backoffice/backoffice_users">Back</router-link>
    </div>
  </BackofficeBase>
</template>

<script>
import BackofficeBase from "./BackofficeBase";
import BackendModel from "../../models/BackendModel";

export default {
  name: 'BackofficeUserEdit',
  components: {
    BackofficeBase
  },
  data() {
    return {
      loader_prop: false,
      backoffice_user_id : '0',
      email : '',
      full_name : '',
      password : '',
      phone : '',
      show_ty : false,
      email_input_error : false,
      phone_input_error : false,
      full_name_input_error : false,
      password_input_error : false
    }
  },
  async mounted() {
    this.backoffice_user_id = this.$route.query.id
    if (this.backoffice_user_id != '0') {
      let backendModel = new BackendModel()
      let backoffice_user_data = await backendModel.backendRequest("/Api/service/backoffice/backoffice_get_user", {"backoffice_user_id": this.backoffice_user_id})
      this.email = backoffice_user_data.data.backoffice_user_data.email
      this.phone = backoffice_user_data.data.backoffice_user_data.phone
      this.full_name = backoffice_user_data.data.backoffice_user_data.full_name
    }
  },
  methods : {
    async backoffice_user_save(e) {
      e.preventDefault()
      
      // Validation
      let errs = 0
      this.email_input_error = false
      this.phone_input_error = false
      this.full_name_input_error = false
      this.password_input_error = false
      if (this.email == '') {
        this.email_input_error = true
        errs++
      }
      if (this.phone == '') {
        this.phone_input_error = true
        errs++
      }
      if (this.full_name == '') {
        this.full_name_input_error = true
        errs++
      }
      if (this.password == '' && this.backoffice_user_id == '0') {
        this.password_input_error = true
        errs++
      }

      if (errs == 0) {
        this.loader_prop = true
        let backendModel = new BackendModel()
        let params = {
          backoffice_user_id : this.backoffice_user_id,
          email : this.email,
          full_name : this.full_name,
          password : this.password,
          phone : this.phone
        }
        await backendModel.backendRequest("/Api/service/backoffice/save_user_backoffice", params)
        this.loader_prop = false
        this.show_ty = true
      }
    }
  }
}
</script>

<style scoped>
@import "../../assets/style.css"
</style>